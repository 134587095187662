/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Fragment,
  FragmentProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { SlSocialInstagram } from "react-icons/sl";
// #endregion

// #region Type Definition
type SocialStatusProps = Partial<ContainerProps> & {
  header: Partial<BoxProps>,
  icon: Partial<IconProps>,
  status: TypographyProps & {},
  lastUpdated: TypographyProps & {},
  action: Partial<BoxProps>,
  statusMessage: Partial<FragmentProps>,
};
// #endregion

// #region SocialStatusComponent
const SocialStatus: React.FC<SocialStatusProps> = (props) => {
  const {
    header,
    icon,
    status,
    lastUpdated,
    action,
    statusMessage,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{
        marginBottom: "16px",
        gap: "16px",
        display: "flex",
        alignItems: "flex-end",
      }}
      {...rootProps}
    >
      <Icon
        color={"inherit"}
        icon={SlSocialInstagram}
        fontSize={"inherit"}
        css={{
          width: "32px",
          height: "32px",
          fontSize: "32px",
          marginBottom: "8px",
        }}
        {...icon}
      />
      <Box tag={"div"} css={{ flexGrow: "1" }}>
        <Box
          tag={"div"}
          css={{
            backgroundColor: "rgba(255,255,255, 1)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000",
            flexGrow: "1",
            borderRadius: "22px",
            marginBottom: "16px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "4px",
            paddingBottom: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          {...header}
        >
          <Typography
            variant={"overline"}
            text={"Status"}
            gutterBottom={false}
            noWrap={false}
            css={{ width: "40%" }}
          />
          <Typography
            variant={"overline"}
            text={"Dernière mise à jour"}
            gutterBottom={false}
            noWrap={false}
            css={{ width: "30%", textAlign: "right", flexGrow: "1" }}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            backgroundColor: "rgba(255,255,255, 1)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000",
            flexGrow: "1",
            borderRadius: "22px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "8px",
            paddingBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Fragment {...statusMessage} />
          <Typography
            variant={"overline"}
            text={"Status"}
            gutterBottom={false}
            noWrap={false}
            css={{ width: "40%" }}
            {...status}
          />
          <Typography
            variant={"overline"}
            text={"Dernière mise à jour"}
            gutterBottom={false}
            noWrap={false}
            css={{ width: "30%", textAlign: "right", flexGrow: "1" }}
            {...lastUpdated}
          />
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{
          width: "180px",
          textAlign: "right",
          gap: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        {...action}
      />
    </Container>
  );
};

export default SocialStatus;
// #endregion
