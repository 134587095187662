/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type SocialItemProps = Partial<ContainerProps> & {
  avatar: Partial<AvatarProps>,
  link: ButtonProps & {},
  name: TypographyProps & {},
  instagram: TypographyProps & {},
};
// #endregion

// #region SocialItemComponent
const SocialItem: React.FC<SocialItemProps> = (props) => {
  const { avatar, link, name, instagram, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{
        padding: "8px",
        boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
        gap: "8px",
        borderRadius: "16px",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Avatar
        variant={"circular"}
        component={"div"}
        css={{ width: "54px", height: "54px" }}
        {...avatar}
      />
      <Box tag={"div"} css={{ flexGrow: "1" }}>
        <Typography
          variant={"h3"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "16px" }}
          {...name}
        />
        <Typography
          variant={"overline"}
          text={"text"}
          gutterBottom={false}
          noWrap={false}
          css={{ marginTop: "8px", lineHeight: "16px" }}
          {...instagram}
        />
      </Box>
      <Button
        text={"Link"}
        type={"button"}
        disabled={false}
        disableElevation={false}
        disableFocusRipple={false}
        disableRipple={false}
        fullWidth={false}
        size={"small"}
        variant={"contained"}
        iconStart={false}
        iconEnd={false}
        css={{
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingTop: "4px",
          paddingBottom: "4px",
        }}
        {...link}
      />
    </Container>
  );
};

export default SocialItem;
// #endregion
