import React, { useMemo, useState, Suspense, useCallback } from "react";
import SocialStatus from "./SocialStatus";
import { IconProps } from "@repleek/mui";
import OAuthPopup from "./OAuthPopup";
import {
  Box,
  Button,
  Modal as MuiModal,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Cached, AccessTime } from "@mui/icons-material";
import SocialSelect from "./SocialSelect";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import { socialsAuthState } from "@/store/auth";
import SocialPageSelect from "./SocialPageSelect";
import { socialJwtState, socialPageAttached } from "@/store/social";
import SocialItem from "./SocialItem";
import { useThrottle } from "@uidotdev/usehooks";
import { JWTSession } from "@/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { useParams } from "react-router-dom";
import { accountSelector } from "@/store/accounts";
import dayjs from "dayjs";
import { PageInfo } from "@/modules/strapi-sdk/lib/interfaces";
import { Modal, PistacheLoader } from "@/components/layout";
import SocialStatisticTable from "./SocialStatisticTable";
import InfoIcon from "@mui/icons-material/Info";
import { SiFacebook, SiLinkedin, SiTiktok } from "react-icons/si";
import { SlSocialInstagram } from "react-icons/sl";

type Social = "linkedin" | "tiktok" | "facebook" | "instagram";

type Props = {
  type: Social;
  header?: boolean;
  lastUpdated?: Date | string;
  status?: "LINKED" | "SESSION_EXPIRED";
  status_message?: string;
};
type ActionProps = {
  type: Social;
  icon: IconProps;
  title: string;
};
type SocialPageListProps = { type: Social; onLinkDone: () => void };

const oauth: Record<Social, { icon: IconProps; title: string }> = {
  tiktok: {
    icon: {
      icon: SiTiktok,
      style: { color: "#000000" },
    },
    title: "Connexion à tiktok",
  },
  facebook: {
    icon: {
      icon: SiFacebook,
      style: { color: "#1877F2" },
    },
    title: "Connexion à facebook",
  },
  instagram: {
    icon: {
      icon: SlSocialInstagram,
      style: { color: "#833AB4" },
    },
    title: "Connexion à instagram",
  },
  linkedin: {
    icon: {
      icon: SiLinkedin,
      style: { color: "#0A66C2" },
    },
    title: "Connexion à linkedIn",
  },
};

const SocialDetail: React.FC<Props> = (props) => {
  const { type, header, lastUpdated, status, status_message } = props;
  const { id } = useParams();
  const account = useRecoilValue(accountSelector(id));
  const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  const [strapi] = useStrapi();
  const [loading, setLoading] = useState(false);
  const [showStatModal, setSetshowStatModal] = useState(false);

  const socialAccount = useMemo(
    () => account?.[`${type}_account`],
    [account, type]
  );
  const { icon, title } = useMemo(() => oauth[type], [type]);

  const onUnLinkPage = useCallback(async () => {
    if (id) {
      const res = await strapi?.update(
        "me/accounts",
        id,
        type === "facebook"
          ? ({
              facebook_account: null,
              instagram_account: null,
            } as any)
          : {
              [`${type}_account`]: null,
            }
      );
      if (res) {
        refreshAccount();
      }
    }
  }, [id, strapi, type, refreshAccount]);

  const onSyncSocialStat = useCallback(async () => {
    if (id) {
      setLoading(true);
      await strapi?.get("social-statistic/:provider/:accountId", {
        ":accountId": id,
        ":provider": type,
      });
      refreshAccount();
      setLoading(false);
    }
  }, [id, refreshAccount, strapi, type]);

  const linkUserAccount = useCallback(
    async (account: JWTSession) => {
      if (id && account.user?.id) {
        const res = await strapi?.post(
          "accounts/:accountId/link-social-page/:provider",
          {
            ":accountId": id,
            ":provider": type,
          },
          {
            access_token: account.access_token,
            expires_in: account.expires_in,
            id: account.user.id,
            name: account.user?.name,
            picture: account.user?.picture,
            refresh_token: account.refresh_token,
            refresh_expires_in: account.refresh_expires_in,
          }
        );
        if (res) {
          refreshAccount();
        }
      }
    },
    [id, strapi, type, refreshAccount]
  );

  const onShowStatModal = useCallback(() => setSetshowStatModal(true), []);

  const actions = useMemo(() => {
    if (socialAccount) {
      return (
        <>
          <IconButton
            color="primary"
            onClick={onShowStatModal}
            disabled={loading}>
            <AccessTime />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={onSyncSocialStat}
            disabled={loading}>
            <Cached />
          </IconButton>
          <Button
            variant="contained"
            color="secondary"
            sx={{ padding: "6px 24px" }}
            onClick={onUnLinkPage}
            disabled={loading}>
            Unlink
          </Button>
        </>
      );
    }
    switch (type) {
      case "facebook":
      case "linkedin":
        return <Action type={type} icon={icon} title={title} />;
      case "instagram":
        return;
      default:
        return <OAuthPopup type={type} onSuccess={linkUserAccount} />;
    }
  }, [
    socialAccount,
    type,
    onShowStatModal,
    loading,
    onSyncSocialStat,
    onUnLinkPage,
    icon,
    title,
    linkUserAccount,
  ]);

  const _lastUpdated = useMemo(
    () => (lastUpdated ? dayjs(lastUpdated).fromNow() : ""),
    [lastUpdated]
  );

  const _status = useMemo(() => {
    if (status === "LINKED") return "Associé";
    if (status === "SESSION_EXPIRED") return "Session expirée";
    return "Non associé";
  }, [status]);

  const statusMessage = useMemo(() => {
    if (status_message)
      return (
        <Tooltip title={status_message} placement="right">
          <InfoIcon sx={{ cursor: "pointer", mr: 1, color: "red" }} />
        </Tooltip>
      );
    return null;
  }, [status_message]);

  return (
    <>
      <SocialStatus
        action={{ children: actions }}
        icon={icon}
        header={{ hidden: !header }}
        status={{ text: _status }}
        lastUpdated={{ text: _lastUpdated }}
        statusMessage={{ hidden: !status_message, children: statusMessage }}
      />

      <Modal
        open={showStatModal}
        title={`Daily ${type} insight`}
        icon={icon}
        onClose={() => setSetshowStatModal(false)}
        noActions
        width="md">
        <SocialStatisticTable id={socialAccount?.id} />
      </Modal>
    </>
  );
};

const Action: React.FC<ActionProps> = (props) => {
  const { type, icon, title } = props;
  const [opened, setOpened] = useState(false);
  const token = useRecoilValue(socialJwtState(type));

  return (
    <>
      <Button
        sx={{ padding: "8px 14px" }}
        variant="contained"
        fullWidth
        color="secondary"
        onClick={() => setOpened(true)}>
        Configuration
      </Button>
      <MuiModal
        open={opened}
        onClose={() => setOpened(false)}
        keepMounted={false}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 8,
          }}>
          {token ? (
            <Suspense fallback={<PistacheLoader />}>
              <SocialPageList type={type} onLinkDone={() => setOpened(false)} />
            </Suspense>
          ) : (
            <SocialSelect
              icon={icon}
              header={{ text: title }}
              cancel={{ onClick: () => setOpened(false) }}
              message={{ type }}
              confirm={{
                children: <OAuthPopup type={type} onSuccess={() => {}} />,
              }}
            />
          )}
        </Box>
      </MuiModal>
    </>
  );
};

const SocialPageList: React.FC<SocialPageListProps> = (props) => {
  const { type, onLinkDone } = props;
  const { id } = useParams();
  const [strapi] = useStrapi();

  const resetToken = useResetRecoilState(socialsAuthState(type));
  const pages = useRecoilValue(socialPageAttached(type));
  const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  const account = useRecoilValue(accountSelector(id));

  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);

  const pagesFilterd = useMemo(
    () =>
      pages?.filter(({ name }) =>
        name?.toLowerCase().includes(throttledSearch.toLocaleLowerCase())
      ),
    [pages, throttledSearch]
  );

  const message = useMemo(
    () =>
      pages?.length
        ? "Choisissez la page que vous souhaitez synchroniser."
        : `Il semble qu'aucune page ne soit actuellement liée à votre compte <b>${type}</b>. Pour ajouter du contenu, veuillez soit lier une page existante, soit en créer une nouvelle et l'associer à votre compte.`,
    [pages, type]
  );

  const onLinkPage = useCallback(
    async (page: PageInfo) => {
      if (id) {
        const res = await strapi?.post(
          "accounts/:accountId/link-social-page/:provider",
          {
            ":accountId": id,
            ":provider": type,
          },
          { ...page, id: page.page_id }
        );
        if (res) {
          refreshAccount();
          onLinkDone();
        }
      }
    },
    [id, strapi, type, refreshAccount, onLinkDone]
  );

  return (
    <SocialPageSelect
      logout={{ onClick: resetToken }}
      list={{
        children: pagesFilterd?.map((page) => (
          <SocialItem
            key={page.id}
            avatar={{
              src: `${process.env.REACT_APP_API_URL}/api/assets?url=${page.picture}`,
              initial: page.name?.substring(0, 2),
            }}
            link={{
              onClick: () => onLinkPage(page),
              text: account?.[`${type}_account`] ? "Unlink" : "Link",
              color: account?.[`${type}_account`] ? "secondary" : "primary",
            }}
            name={{ text: page.name }}
            instagram={{
              text: page.connected_instagram_account?.id
                ? "Instgram disponible"
                : "Instagram indisponible",
              hidden: type !== "instagram",
            }}
          />
        )),
      }}
      message={{ text: message }}
      search={{
        inputName: "search",
        onKeyUp: (event) => setSearch(event.currentTarget.value),
      }}
    />
  );
};

export default SocialDetail;
