/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  Paper,
  PaperProps,
  Typography,
} from "@repleek/mui";
import { IoMdAdd } from "react-icons/io";
// #endregion

// #region Type Definition
type ListReportProps = Partial<ContainerProps> & {
  reporting: Partial<PaperProps>,
  newReporting: ButtonProps & {},
  pagination: Partial<BoxProps>,
};
// #endregion

// #region ListReportComponent
const ListReport: React.FC<ListReportProps> = (props) => {
  const { reporting, newReporting, pagination, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ paddingTop: "32px" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant={"h2"}
          text={"Reporting"}
          gutterBottom={false}
          noWrap={false}
          css={{
            fontSize: "32px",
            fontWeight: 700,
            marginBottom: "16px",
            textTransform: "uppercase",
          }}
        />
        <Button
          text={"GENERER"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"medium"}
          variant={"contained"}
          color={"primary"}
          iconStart={true}
          iconEnd={false}
          href={"/new-reporting"}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          IconStart={
            <Icon
              color={"inherit"}
              icon={IoMdAdd}
              fontSize={"inherit"}
              hidden={false}
            />
          }
          {...newReporting}
        />
      </Box>
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          padding: "32px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#000",
          borderRadius: "22px",
          marginTop: "16px",
          overflow: "hidden",
          overflowY: "auto",
          minHeight: "45vh",
        }}
        {...reporting}
      />
      <Box
        tag={"div"}
        css={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
        {...pagination}
      />
    </Container>
  );
};

export default ListReport;
// #endregion
