/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { BsQuestionCircle } from "react-icons/bs";
// #endregion

// #region Type Definition
type SocialSelectProps = Partial<ContainerProps> & {
  icon: Partial<IconProps>,
  header: TypographyProps & {},
  cancel: ButtonProps & {},
  message: TypographyProps & {
    type?: string,
  },
  confirm: Partial<BoxProps>,
};
// #endregion

// #region SocialSelectComponent
const SocialSelect: React.FC<SocialSelectProps> = (props) => {
  const { icon, header, cancel, message, confirm, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      css={{
        padding: "16px",
        width: "560px",
        backgroundColor: "rgba(255,255,255, 1)",
      }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ display: "flex", alignItems: "center" }}>
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={BsQuestionCircle}
          css={{ fontSize: "54px" }}
          {...icon}
        />
        <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "14px" }}>
          <Typography
            variant={"h1"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "22px" }}
            {...header}
          />
        </Box>
      </Box>
      <Typography
        variant={"caption"}
        text={
          "Pour avoir la possibilité de sélectionner une page, il est nécessaire de se connecter à un compte <b><u>{{type}}</u></b>."
        }
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "14px", paddingTop: "8px" }}
        {...message}
      />
      <Box
        tag={"div"}
        css={{
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          text={"Annuler"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          {...cancel}
        />
        <Box tag={"div"} {...confirm} />
      </Box>
    </Container>
  );
};

export default SocialSelect;
// #endregion
