import React, { useCallback } from "react";
import { Modal } from "@/components/layout";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import UserForm from "./UserForm";
import { useStrapi } from "@/providers/StrapiProvider";
import { useRecoilValue } from "recoil";
import { authState } from "@/store/auth";
import { FaRegUserCircle } from "react-icons/fa";

type Props = {
  user?: Partial<User>;
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
};

const UserModal: React.FC<Props> = (props) => {
  const { user, open, onClose, onSave } = props;
  const [strapi, { loading }] = useStrapi();

  const userLogged = useRecoilValue(authState);

  const onSubmit = useCallback(
    async (user: User) => {
      try {
        if (user.id) {
          // update user
          const res = await strapi?.update("me/users", user.id, user);
          if (!res?.data?.id) return;
        } else {
          // create user
          const res = await strapi?.create("me/users", user);
          if (!res?.data?.id) return;
        }
        onSave?.();
        onClose();
      } catch (error) {}
    },
    [onClose, onSave, strapi]
  );

  return (
    <Modal
      open={open}
      loading={loading}
      title={user?.id ? "Mise à jour utilisateur" : "Création utilisateur"}
      form={{ defaultValues: user, onSubmit }}
      icon={{ icon: FaRegUserCircle }}
      onClose={onClose}
      onConfirm={() => {}}>
      <UserForm
        email={{ inputName: "email" }}
        type={{
          inputName: "type",
          options: [
            { label: "Client", value: "customer" },
            { label: "Administrateur", value: "admin" },
            { label: " Community Manager", value: "cm" },
          ],
          disabled: userLogged?.type !== "admin",
        }}
      />
    </Modal>
  );
};

export default UserModal;
