import { Button } from "@mui/material";
import React from "react";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";

type Props = {
  menu: {
    label: string;
    href: string;
    icon: IconType;
  }[];
};

const Menu: React.FC<Props> = ({ menu }) => {
  if (!menu.length) return null;

  return (
    <>
      {menu.map(({ href, label, icon: Icon }) => (
        <NavLink key={href} to={href} end>
          {({ isActive }) => (
            <Button
              startIcon={<Icon />}
              sx={{
                justifyContent: "start",
                borderColor: "transparent",
                boxShadow: "none",
                mb: 0.5,
                ":hover": {
                  boxShadow: "none",
                },
              }}
              fullWidth
              color="secondary"
              size="small"
              variant={isActive ? "contained" : "outlined"}>
              {label}
            </Button>
          )}
        </NavLink>
      ))}
    </>
  );
};

export default Menu;
