/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoIosPaperPlane } from "react-icons/io";
// #endregion

// #region Type Definition
type AccountDetailProps = Partial<ContainerProps> & {
  logo: Partial<AvatarProps>,
  editBtn: ButtonProps & {},
  name: TypographyProps & {},
  email: TypographyProps & {},
  notification: Partial<ButtonIconProps>,
};
// #endregion

// #region AccountDetailComponent
const AccountDetail: React.FC<AccountDetailProps> = (props) => {
  const { logo, editBtn, name, email, notification, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "32px", marginBottom: "32px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={1}
        css={{
          padding: "18px",
          gap: "12px",
          borderRadius: "22px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          variant={"rounded"}
          component={"div"}
          css={{ width: "64px", height: "64px" }}
          {...logo}
        />
        <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "16px" }}>
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "26px" }}
            {...name}
          />
          <Typography
            variant={"body1"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            {...email}
          />
        </Box>
        <Box tag={"div"}>
          <ButtonIcon
            type={"button"}
            disabled={false}
            disableFocusRipple={false}
            disableRipple={false}
            color={"secondary"}
            css={{ marginRight: "8px" }}
            Icon={
              <Icon
                color={"inherit"}
                icon={IoIosPaperPlane}
                fontSize={"inherit"}
              />
            }
            {...notification}
          />
          <Button
            text={"Modifier"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
            css={{
              paddingLeft: "12px",
              paddingRight: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            {...editBtn}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default AccountDetail;
// #endregion
