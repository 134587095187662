/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  Container,
  Form,
  FormProps,
  TextField,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type LoginFormProps = Partial<FormProps> & {
  form: Partial<FormProps>,
};
// #endregion

// #region LoginFormComponent
const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { form, ...rootProps } = props;

  return (
    <Form mode={"onSubmit"} {...form} {...rootProps}>
      <Container maxWidth={"xs"} disableGutters={false} fixed={false}>
        <Typography
          variant={"h1"}
          text={"Connexion"}
          gutterBottom={false}
          noWrap={false}
          css={{
            marginBottom: "22px",
            textTransform: "uppercase",
            fontSize: "52px",
            fontFamily: "Unbounded",
            fontStyle: "normal",
            fontWeight: "600",
          }}
        />
        <Box tag={"div"} css={{ marginBottom: "16px" }}>
          <TextField
            inputName={"email"}
            label={"E-mail"}
            variant={"outlined"}
            color={"secondary"}
            type={"email"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={true}
            size={"medium"}
            css={{ marginBottom: "16px" }}
          />
          <TextField
            inputName={"password"}
            label={"Mot de passe"}
            variant={"outlined"}
            color={"secondary"}
            type={"password"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={true}
            size={"medium"}
            css={{ marginBottom: "12px" }}
          />
          <Box tag={"div"} css={{ textAlign: "right" }}>
            <Typography
              variant={"overline"}
              text={"<span>Mot de passe oublié ?</span>"}
              href={"/reset-password"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontFamily: "Unbounded", fontSize: "12px" }}
            />
          </Box>
        </Box>
        <Button
          text={"Connexion"}
          type={"submit"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={true}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
        />
      </Container>
    </Form>
  );
};

export default LoginForm;
// #endregion
