/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  Container,
  Form,
  FormProps,
  TextField,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ResetPasswordFormProps = Partial<FormProps> & {
  form: Partial<FormProps>,
  title: TypographyProps & {},
  resetPass: Partial<BoxProps>,
  changePass: Partial<BoxProps>,
  info: TypographyProps & {},
};
// #endregion

// #region ResetPasswordFormComponent
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { form, title, resetPass, changePass, info, ...rootProps } = props;

  return (
    <Form mode={"onSubmit"} {...form} {...rootProps}>
      <Container maxWidth={"xs"} disableGutters={false} fixed={false}>
        <Box tag={"div"} css={{ marginBottom: "16px" }}>
          <Typography
            variant={"h1"}
            text={"Réinitialisation"}
            gutterBottom={false}
            noWrap={false}
            css={{
              marginBottom: "22px",
              textTransform: "uppercase",
              fontSize: "36px",
            }}
            {...title}
          />
          <Typography
            variant={"body1"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ marginBottom: "16px" }}
            {...info}
          />
          <Box tag={"div"} {...resetPass}>
            <TextField
              inputName={"email"}
              label={"E-mail"}
              variant={"outlined"}
              color={"secondary"}
              type={"email"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"medium"}
              css={{ marginBottom: "16px" }}
            />
          </Box>
          <Box tag={"div"} {...changePass}>
            <TextField
              inputName={"password"}
              label={"Mot de passe"}
              variant={"outlined"}
              color={"secondary"}
              type={"password"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"medium"}
              css={{ marginBottom: "16px" }}
            />
            <TextField
              inputName={"passwordConfirmation"}
              label={"Confirmation"}
              variant={"outlined"}
              color={"secondary"}
              type={"password"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"medium"}
              css={{ marginBottom: "16px" }}
            />
          </Box>
        </Box>
        <Button
          text={"Envoyer"}
          type={"submit"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={true}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
        />
      </Container>
    </Form>
  );
};

export default ResetPasswordForm;
// #endregion
