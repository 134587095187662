import React, { Suspense, useCallback, useMemo } from "react";
import { AccountForm, NotFound } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { Account } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { accountSelector, accountsSelector } from "@/store/accounts";
import { useNavigate } from "@repleek/mui";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

const NewAccount: React.FC = () => {
  return (
    <Layout title="Pistache - nouveau compte" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Form />
      </Suspense>
    </Layout>
  );
};

const Form: React.FC = () => {
  const { id } = useParams();
  const [strapi, { loading }] = useStrapi();
  const { navigate } = useNavigate();
  const account = useRecoilValue(accountSelector(id));
  const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  const refreshAccounts = useRecoilRefresher_UNSTABLE(accountsSelector);

  const defaultValues = useMemo(
    () =>
      account || {
        type: "classic",
        configuration: { facebook: true, instagram: true, tiktok: true },
      },
    [account]
  );

  const onSubmit = useCallback(
    async (value: Account) => {
      let newAccount: Account | undefined;
      if (!id) {
        // create
        const res = await strapi?.create("me/accounts", value);
        newAccount = res?.data;
      } else {
        // update
        const res = await strapi?.update("me/accounts", id, value);
        newAccount = res?.data;
      }
      if (newAccount) {
        navigate?.(`/account/${newAccount.id}`);
        refreshAccount();
        refreshAccounts();
      }
    },
    [id, navigate, refreshAccount, refreshAccounts, strapi]
  );

  if (!account && id) return <NotFound />;

  return (
    <AccountForm
      title={{ text: id ? "Mise à jour" : "Nouveau compte" }}
      form={{ onSubmit, defaultValues }}
      email={{ inputName: "email", disabled: !!id }}
      back={{ onClick: () => navigate?.(id ? `/account/${id}` : "/accounts") }}
      type={{
        inputName: "type",
        options: [
          { label: "Classique", value: "classic" },
          { label: "Réstaurant", value: "restaurant" },
        ],
      }}
      fields={{ hidden: loading }}
      loader={{ children: <PistacheLoader />, hidden: !loading }}
    />
  );
};

export default NewAccount;
