/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  Image,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
import { CiBrightnessUp, CiDark } from "react-icons/ci";
import { GoBellFill } from "react-icons/go";
// #endregion

// #region Type Definition
type AdminLayoutProps = Partial<BoxProps> & {
  sidebar: Partial<BoxProps>,
  body: Partial<ContainerProps>,
  user: Partial<BoxProps>,
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  menu: Partial<BoxProps>,
  logout: ButtonProps & {},
  login: ButtonProps & {},
  light: Partial<ButtonIconProps>,
  notification: Partial<ButtonIconProps>,
  dark: Partial<ButtonIconProps>,
  breadcrumb: Partial<BoxProps>,
};
// #endregion

// #region AdminLayoutComponent
const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const {
    sidebar,
    body,
    user,
    avatar,
    name,
    menu,
    logout,
    login,
    light,
    notification,
    dark,
    breadcrumb,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: "rgba(246, 245, 245, 1)",
        display: "flex",
        ".mui-dark &": { backgroundColor: "rgba(21, 20, 25, 1)" },
      }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ padding: "12px" }}>
        <Box
          tag={"div"}
          css={{
            width: "280px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "24px",
            paddingBottom: "16px",
            height: "100%",
            backgroundColor: "rgba(255,255,255, 1)",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
          }}
          {...sidebar}
        >
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo.png"}
            href={"/"}
            dynamic={true}
            css={{ width: "100%" }}
          />
          <Box
            tag={"div"}
            css={{
              textAlign: "center",
              paddingTop: "32px",
              paddingBottom: "32px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            {...user}
          >
            <Avatar
              variant={"circular"}
              initial={"AA"}
              component={"div"}
              css={{
                width: "84px",
                height: "84px",
                marginBottom: "8px",
                fontSize: "28px",
                fontFamily: "Unbounded",
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor: "rgb(239, 239, 250)",
                color: "rgba(255,255,255, 1)",
                background:
                  "linear-gradient(90deg, rgb(151, 150, 240) 0%, rgb(251, 199, 212) 100%)",
              }}
              {...avatar}
            />
            <Typography
              variant={"h3"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "20px" }}
              {...name}
            />
          </Box>
          <Box tag={"div"} css={{ flexGrow: "1" }} {...menu} />
          <Button
            text={"Se déconnecter "}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            iconStart={true}
            iconEnd={false}
            css={{
              fontSize: "14px",
              paddingLeft: "14px",
              paddingRight: "8px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            IconStart={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={AiOutlineLogout}
              />
            }
            {...logout}
          />
          <Button
            text={"Se connecter "}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            color={"primary"}
            iconStart={true}
            iconEnd={false}
            href={"/login"}
            css={{
              fontSize: "14px",
              paddingLeft: "14px",
              paddingRight: "8px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            IconStart={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={AiOutlineLogin}
              />
            }
            {...login}
          />
        </Box>
      </Box>
      <Box tag={"div"} css={{ overflowY: "auto", flexGrow: "1" }}>
        <Container
          maxWidth={"lg"}
          disableGutters={false}
          fixed={false}
          css={{
            paddingTop: "24px",
            paddingBottom: "8px",
            position: "sticky",
            top: "0px",
            backgroundColor: "rgba(246, 245, 245, 1)",
            zIndex: "1000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ".mui-dark &": { backgroundColor: "rgba(21, 20, 25, 1)" },
          }}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }} {...breadcrumb} />
          <Box tag={"div"}>
            <ButtonIcon
              type={"button"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              color={"inherit"}
              Icon={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={CiBrightnessUp}
                />
              }
              {...dark}
            />
            <ButtonIcon
              type={"button"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              color={"inherit"}
              Icon={
                <Icon color={"inherit"} fontSize={"inherit"} icon={CiDark} />
              }
              {...light}
            />
            <ButtonIcon
              type={"button"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              color={"secondary"}
              css={{ marginLeft: "12px" }}
              Icon={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={GoBellFill}
                />
              }
              {...notification}
            />
          </Box>
        </Container>
        <Container
          maxWidth={"lg"}
          disableGutters={false}
          fixed={false}
          css={{ paddingTop: "8px" }}
          {...body}
        />
      </Box>
    </Box>
  );
};

export default AdminLayout;
// #endregion
