/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Fragment,
  FragmentProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ReportingViewHeaderProps = Partial<ContainerProps> & {
  action: ButtonProps & {},
  form: Partial<FormProps>,
  dateRange: Partial<FragmentProps>,
  logo: Partial<AvatarProps>,
  name: TypographyProps & {},
  email: TypographyProps & {},
};
// #endregion

// #region ReportingViewHeaderComponent
const ReportingViewHeader: React.FC<ReportingViewHeaderProps> = (props) => {
  const { action, form, dateRange, logo, name, email, ...rootProps } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={false}
      fixed={false}
      css={{
        padding: "32px",
        backgroundColor: "rgba(255,255,255, 1)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "16px",
        marginBottom: "16px",
      }}
      {...rootProps}
    >
      <Form
        mode={"onSubmit"}
        css={{
          gap: "12px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        {...form}
      >
        <Box
          tag={"div"}
          css={{
            flexGrow: "1",
            gap: "12px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            variant={"rounded"}
            component={"div"}
            css={{ width: "64px", height: "64px" }}
            {...logo}
          />
          <Box tag={"div"} css={{ flexGrow: "1" }}>
            <Typography
              variant={"h4"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              {...name}
            />
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              {...email}
            />
          </Box>
        </Box>
        <Fragment {...dateRange} />
        <Button
          text={"Filtrer"}
          type={"submit"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          {...action}
        />
      </Form>
    </Container>
  );
};

export default ReportingViewHeader;
// #endregion
