/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Fragment,
  FragmentProps,
  Image,
  ImageProps,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type LoadingProps = Partial<BoxProps> & {
  logo: Partial<ImageProps>,
  loader: Partial<FragmentProps>,
};
// #endregion

// #region LoadingComponent
const Loading: React.FC<LoadingProps> = (props) => {
  const { logo, loader, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ textAlign: "center" }}>
        <Box tag={"div"} css={{ position: "relative" }}>
          <Fragment {...loader} />
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo-mini.png"}
            dynamic={true}
            {...logo}
          />
        </Box>
        <Typography
          variant={"h4"}
          text={"Loading ..."}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "22px", fontFamily: "Unbounded" }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
// #endregion
