/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, TextField, TextFieldProps } from "@repleek/mui";
// #endregion

// #region Type Definition
type StatisticFieldsProps = Partial<BoxProps> & {
  followers: Partial<TextFieldProps>,
  posts: Partial<TextFieldProps>,
  impressions: Partial<TextFieldProps>,
  interactions: Partial<TextFieldProps>,
  comment: Partial<TextFieldProps>,
};
// #endregion

// #region StatisticFieldsComponent
const StatisticFields: React.FC<StatisticFieldsProps> = (props) => {
  const { followers, posts, impressions, interactions, comment, ...rootProps } =
    props;

  return (
    <Box tag={"div"} {...rootProps}>
      <Box
        tag={"div"}
        css={{
          gap: "16px",
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          inputName={"input"}
          label={"Nb de followers"}
          variant={"outlined"}
          color={"primary"}
          type={"number"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={false}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"medium"}
          {...followers}
        />
        <TextField
          inputName={"input"}
          label={"Nb d'intéractions"}
          variant={"outlined"}
          color={"primary"}
          type={"number"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={false}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"medium"}
          {...interactions}
        />
        <TextField
          inputName={"input"}
          label={"Nb d'impressions"}
          variant={"outlined"}
          color={"primary"}
          type={"number"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={false}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"medium"}
          {...impressions}
        />
        <TextField
          inputName={"input"}
          label={"Nb de posts"}
          variant={"outlined"}
          color={"primary"}
          type={"number"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={false}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"medium"}
          {...posts}
        />
      </Box>
      <TextField
        inputName={"Input name"}
        label={"Commentaire"}
        variant={"outlined"}
        color={"primary"}
        type={"text"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={"3"}
        minRows={"3"}
        rows={"2"}
        multiline={true}
        readOnly={false}
        required={false}
        size={"medium"}
        {...comment}
      />
    </Box>
  );
};

export default StatisticFields;
// #endregion
