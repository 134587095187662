import { LayoutDefinition } from "@/interfaces";
import { layoutState } from "@/store/layout";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export const useBreadCrumb = (breadcrumbs: LayoutDefinition["breadcrumbs"]) => {
  const [layout, setLayout] = useRecoilState(layoutState);

  useEffect(() => {
    setLayout({ ...layout, breadcrumbs });
    return () => {
      setLayout({ ...layout, breadcrumbs: [] });
    };
  }, []);
};
