/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  Icon,
  InputBase,
  InputBaseProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FiLogOut } from "react-icons/fi";
// #endregion

// #region Type Definition
type SocialPageSelectProps = Partial<ContainerProps> & {
  search: Partial<InputBaseProps>,
  list: Partial<BoxProps>,
  message: TypographyProps & {},
  logout: ButtonProps & {},
};
// #endregion

// #region SocialPageSelectComponent
const SocialPageSelect: React.FC<SocialPageSelectProps> = (props) => {
  const { search, list, message, logout, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{ minWidth: "540px", paddingTop: "8px", paddingBottom: "24px" }}
      {...rootProps}
    >
      <Container
        maxWidth={"xs"}
        disableGutters={false}
        fixed={false}
        css={{ gap: "8px", display: "flex", alignItems: "center" }}
      >
        <Form
          mode={"onChange"}
          css={{
            padding: "4px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#333232",
            gap: "8px",
            flexGrow: "1",
            borderRadius: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputBase
            inputName={"search"}
            color={"primary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            placeholder={"Recherche"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={false}
            size={"medium"}
            css={{ paddingLeft: "16px" }}
            {...search}
          />
        </Form>
        <Button
          text={"log out"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={true}
          css={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          IconEnd={
            <Icon
              color={"inherit"}
              icon={FiLogOut}
              fontSize={"inherit"}
              hidden={false}
            />
          }
          {...logout}
        />
      </Container>
      <Typography
        variant={"body2"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ textAlign: "center", paddingTop: "16px", paddingBottom: "16px" }}
        {...message}
      />
      <Box
        tag={"div"}
        css={{ padding: "12px", maxHeight: "50vh", overflowY: "auto" }}
        {...list}
      />
    </Container>
  );
};

export default SocialPageSelect;
// #endregion
