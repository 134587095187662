/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  Container,
  Divider,
  Form,
  FormProps,
  Icon,
  Paper,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaReact } from "react-icons/fa";
// #endregion

// #region Type Definition
type AccountFormProps = Partial<FormProps> & {
  form: Partial<FormProps>,
  fields: Partial<BoxProps>,
  title: TypographyProps & {},
  email: Partial<TextFieldProps>,
  back: ButtonProps & {},
  type: Partial<SelectFieldProps>,
  loader: Partial<BoxProps>,
};
// #endregion

// #region AccountFormComponent
const AccountForm: React.FC<AccountFormProps> = (props) => {
  const { form, fields, title, email, back, type, loader, ...rootProps } =
    props;

  return (
    <Form mode={"onSubmit"} css={{ fontSize: "12px" }} {...form} {...rootProps}>
      <Container maxWidth={"sm"} disableGutters={false} fixed={false}>
        <Paper
          square={false}
          variant={"outlined"}
          elevation={0}
          css={{
            padding: "42px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000000",
            borderRadius: "26px",
          }}
        >
          <Typography
            variant={"h1"}
            text={"Nouveau compte"}
            gutterBottom={false}
            noWrap={false}
            css={{
              marginBottom: "22px",
              textTransform: "uppercase",
              fontSize: "36px",
            }}
            {...title}
          />
          <Box tag={"div"} {...loader} />
          <Box tag={"div"} css={{ marginBottom: "16px" }} {...fields}>
            <TextField
              inputName={"name"}
              label={"Nom du compte"}
              variant={"outlined"}
              color={"secondary"}
              type={"text"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"medium"}
              css={{ marginBottom: "16px" }}
            />
            <Box
              tag={"div"}
              css={{ marginBottom: "16px", gap: "8px", display: "flex" }}
            >
              <TextField
                inputName={"email"}
                label={"E-mail"}
                variant={"outlined"}
                color={"secondary"}
                type={"email"}
                aria-label={"Input Base Mui"}
                autoFocus={false}
                disabled={false}
                fullWidth={true}
                maxRows={1}
                minRows={1}
                rows={1}
                multiline={false}
                readOnly={false}
                required={true}
                size={"medium"}
                {...email}
              />
              <SelectField
                options={[{ label: "Default", value: "default" }]}
                inputName={"type"}
                label={"Type de compte"}
                variant={"outlined"}
                color={"secondary"}
                aria-label={"Input Base Mui"}
                autoFocus={false}
                disabled={false}
                fullWidth={true}
                multiple={false}
                readOnly={false}
                required={true}
                size={"medium"}
                css={{ maxWidth: "186px" }}
                {...type}
              />
            </Box>
            <Checkbox
              inputName={"notification"}
              label={"Activer la notification par email"}
              color={"primary"}
              isChecked={false}
              disabled={false}
              disableTypography={false}
              aria-label={"Input Base Mui"}
              size={"medium"}
            />
            <Divider
              label={"Utilisateur"}
              chip={true}
              flexItem={false}
              light={false}
              orientation={"horizontal"}
              textAlign={"left"}
              variant={"fullWidth"}
              Chip={
                <Chip
                  label={"Réseaux sociaux"}
                  avatar={false}
                  icon={false}
                  clickable={false}
                  color={"secondary"}
                  variant={"filled"}
                  css={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    textTransform: "uppercase",
                  }}
                  hidden={false}
                  Avatar={
                    <Avatar
                      variant={"circular"}
                      component={"div"}
                      className={
                        "MuiChip-avatar MuiChip-avatarMedium MuiChip-avatarColorDefault"
                      }
                    />
                  }
                  Icon={
                    <Icon
                      color={"inherit"}
                      icon={FaReact}
                      fontSize={"inherit"}
                      className={
                        "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
                      }
                    />
                  }
                />
              }
            />
            <Box
              tag={"div"}
              css={{
                gridTemplateColumns: "1fr 1fr",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Checkbox
                inputName={"configuration.facebook"}
                label={"Facebook"}
                color={"primary"}
                isChecked={false}
                disabled={false}
                disableTypography={false}
                aria-label={"Input Base Mui"}
                size={"medium"}
                css={{ minWidth: "50%" }}
              />
              <Checkbox
                inputName={"configuration.instagram"}
                label={"Instagram"}
                color={"primary"}
                isChecked={false}
                disabled={false}
                disableTypography={false}
                aria-label={"Input Base Mui"}
                size={"medium"}
              />
              <Checkbox
                inputName={"configuration.tiktok"}
                label={"TikTok"}
                color={"primary"}
                isChecked={false}
                disabled={false}
                disableTypography={false}
                aria-label={"Input Base Mui"}
                size={"medium"}
                css={{ minWidth: "50%" }}
              />
              <Checkbox
                inputName={"configuration.linkedin"}
                label={"LinkedIn"}
                color={"primary"}
                isChecked={false}
                disabled={false}
                disableTypography={false}
                aria-label={"Input Base Mui"}
                size={"medium"}
              />
            </Box>
            <Box
              tag={"div"}
              css={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text={"Retour"}
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                color={"secondary"}
                iconStart={false}
                iconEnd={false}
                {...back}
              />
              <Button
                text={"Enregistrer"}
                type={"submit"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                variant={"contained"}
                iconStart={false}
                iconEnd={false}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default AccountForm;
// #endregion
