import React, {
  PropsWithChildren,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useStrapi } from "@/providers/StrapiProvider";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { authState } from "@/store/auth";
import { useAuth } from "@/hooks/auth";
import LoginPage from "../features/auth/LoginPage";
import Menu from "./Menu";
import { navigations } from "@/pages";
import BreadCrumb from "./BreadCrumb";
import { Drawer } from "@mui/material";
import { notificationModal, notificationsSelector } from "@/store/notification";
import Notifications from "./Notifications";
import AdminLayout from "./AdminLayout";
import { useColorMode } from "@repleek/mui";

type LayoutProps = {
  mode: "LOGGED_IN" | "LOGGED_OUT" | "ADMIN";
  title?: string;
  redirect?: string;
};

const Layout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  const { title = "Pistache Report", mode, redirect, children } = props;

  const [strapi] = useStrapi();
  const user = useRecoilValue(authState);
  const autorized = useAuth({ mode, redirect });
  const [notification, setNotification] = useRecoilState(notificationModal);
  const refreshNotifications = useRecoilRefresher_UNSTABLE(
    notificationsSelector
  );
  const { mode: theme, setMode } = useColorMode();

  const menu = useMemo(() => {
    switch (user?.type) {
      case "admin":
      case "cm":
        return navigations["admin"];

      case "customer":
        return navigations["customer"];

      default:
        return navigations["default"];
    }
  }, [user]);

  const userName = useMemo(
    () => ({
      name:
        [user?.firstName, user?.lastName].filter((v) => v).join(" ") ||
        user?.email,
      initial: [user?.firstName?.[0], user?.lastName?.[0]]
        .filter((v) => v)
        .join(""),
    }),
    [user]
  );

  const openNotification = useCallback(() => {
    refreshNotifications();
    setNotification(true);
  }, [refreshNotifications, setNotification]);

  const logout = useCallback(() => {
    user && strapi?.logout();
  }, [user, strapi]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <AdminLayout
        body={{ children: autorized ? children : <LoginPage /> }}
        user={{ hidden: !user }}
        avatar={{ initial: userName.initial, hidden: !userName.initial }}
        name={{ text: userName.name }}
        logout={{ onClick: logout, hidden: !user }}
        login={{ hidden: !!user }}
        breadcrumb={{
          children: <BreadCrumb />,
        }}
        notification={{
          onClick: openNotification,
          hidden: !user || !["admin", "cm"].includes(user.type),
        }}
        light={{ onClick: () => setMode("dark"), hidden: theme === "dark" }}
        dark={{ onClick: () => setMode("light"), hidden: theme === "light" }}
        menu={{ children: <Menu menu={menu} /> }}
        sidebar={{ hidden: !user }}
      />

      <Drawer
        open={notification}
        anchor="right"
        onClose={() => setNotification(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 16px)",
            top: "8px",
            right: "12px",
            borderRadius: "18px",
          },
        }}>
        <Notifications />
      </Drawer>
    </>
  );
};

export default Layout;
export * from "./modals";
